import React, { memo, useEffect } from "react";
import { Handle, Position, NodeResizer } from "reactflow";
import PropTypes from 'prop-types';

const DEFAULT_HANDLE_STYLE = { width: 10, height: 10, bottom: -5, };

const Image =  ({ data, isConnectable=true, selected }) => {
    useEffect (() => {
        console.log (selected);
    }, [selected])
    return (
        <div>
            <NodeResizer color="#ff0071" isVisible={selected} minWidth={100} minHeight={100} keepAspectRatio />
            <div style={{ marginTop: "-5px", display: "flex", alignItems: "center", justifyContent: "center" }}>{data.label}</div>
            <Handle
                type="target"
                id="red"
                position={Position.Left}
                style={{ ...DEFAULT_HANDLE_STYLE, top: "50%", background: "red" }}
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                id="red"
                position={Position.Right}
                style={{ ...DEFAULT_HANDLE_STYLE, top: "50%", background: "red" }}
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />
        </div>
    );
};

// Rectangle.displayName = "Rectangle";
export default memo (Image);

Image.propTypes = {
    data: PropTypes.object.isRequired,
    isConnectable: PropTypes.bool,
    selected: PropTypes.bool,
};