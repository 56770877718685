import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import * as d3 from 'd3';
import { OrgChart } from 'd3-org-chart';
import ReactDOMServer from "react-dom/server";

const ButtonComp = ({node}) => {
  return (
    <Box style={{ margin: "auto", padding: "5px", border: "1px solid", borderColor: "#E4E2E9", borderRadius: 5, backgroundColor: "white"}}> 
      <FontAwesomeIcon color="#546E7A" icon={node.children? "fas fa-angle-up": "fas fa-angle-down"}/>&nbsp;{node.data._directSubordinates}
    </Box>
  );
};
ButtonComp.propTypes = {
  node: PropTypes.object.isRequired
};

const CardComp = ({node}) => {
  const color="#FCFEFE"
  return (
    <div style={{ backgroundColor: color, position:"absolute", marginTop:"-1px", marginLeft: "-1px", borderRadius: "10px", border: "1px solid #E4E2E9", width: node.width + "px",  }}>
      <img src={node.data.profile?.avatar || "/app/getImage?id=f39a8522-399c-4fbd-8e49-37c174a59939"} style={{position:"absolute", marginTop:"-20px", marginLeft: "20px", borderRadius: "100px", width:"40px", height:"40px" }} />
      <div style={{ color:"#004D40", marginLeft: "20px", marginRight: "5px", marginTop: "24px", fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: 400, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{node.data.profile?.name || "Yet to appoint"} </div>
      <div style={{ color: "#283593", marginLeft: "20px", marginRight: "5px", fontSize: "12px", fontFamily: "Poppins, sans-serif", fontWeight: 400, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}><FontAwesomeIcon icon={"fas fa-pen-nib"}/>&nbsp;{node.data.role || "Set Me (Role)"} </div>
      <div style={{ color: "#EF6C00", marginLeft: "20px", marginRight: "5px", fontSize: "10px", fontFamily: "Poppins, sans-serif", fontWeight: 400, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}><FontAwesomeIcon icon={"fas fa-location-dot"}/>&nbsp;{node.data.office || "Set Me (Office)"} </div>
      <div style={{ color: "#6B7280", marginLeft: "20px", marginRight: "5px", fontSize: "10px", fontFamily: "Poppins, sans-serif", fontWeight: 400, marginBottom: "10px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}><FontAwesomeIcon icon={"fas fa-scroll"}/>&nbsp;{node.data.description || "Set Me (Role Description)"} </div>
    </div>
  );
};
CardComp.propTypes = {
  node: PropTypes.object.isRequired
};

const D3OrganizationChart = ({schema}) => {
  const d3Container = useRef(null);
  const [settings, setSettings] = useState ({});
  const [data, setData] = useState(null);
  const [chart] = useState(new OrgChart());
  
  useEffect (()=>{
    setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
    schema.addFn("onUpdateNode", onUpdateNode);
    setData (initialData);
    schema.setPropertyValue (initialData);
  }, []);
  useEffect (()=>{
    if (schema.settings.__hasSettingsUpdated){
        setSettings (schema.settings);
        // This is to hold the change in datasource every time
        schema.getParent ().__setSettings__ (schema.__name, {__hasSettingsUpdated: false});
    }
  }, [schema.settings]);

  const onUpdateNode = (node) => { 
    chart.clearHighlighting();
    chart.data (schema.getPropertyValue()).render (); 
    node && chart.setUpToTheRootHighlighted(node.id).render();
  }

  useLayoutEffect(() => {
    if (data && d3Container.current) {
      chart
        .container(d3Container.current)
        .data(data)
        .svgWidth(500)
        .svgHeight (700)
        .initialZoom(1)
        .onNodeClick(function (d){
          chart.clearHighlighting();
          chart.setUpToTheRootHighlighted(d.id).render();
          schema.onNodeClick && schema.onNodeClick(d)
        })
        .nodeHeight(() => 100)
        .nodeWidth(() => 250)
        .childrenMargin(() => 50)
        .compactMarginBetween(() => 25)
        .compactMarginPair(() => 50)
        .neighbourMargin(() => 25)
        .siblingsMargin(() => 25)
        .buttonContent(({ node }) => ReactDOMServer.renderToStaticMarkup(<ButtonComp node={node} />))
        .linkUpdate(function (d) {
          d3.select(this)
            .attr('stroke', (d) => d.data._upToTheRootHighlighted ? '#B0BEC5' : '#E4E2E9' )
            .attr('stroke-width', (d) => d.data._upToTheRootHighlighted ? 2 : 1 );
          if (d.data._upToTheRootHighlighted) d3.select(this).raise();
        })
        .nodeContent((d) => ReactDOMServer.renderToStaticMarkup(<CardComp node={d} />))
        .nodeUpdate(function () {
          d3.select(this)
            .attr('stroke', (d) => d.data._highlighted || d.data._upToTheRootHighlighted ? '#CFD8DC' : "none" )
            .attr('stroke-width', (d) => d.data._highlighted || d.data._upToTheRootHighlighted ? 5 : 1 );
        })
        .render();
    }
  }, [data, d3Container.current]);
    
  return (
      <>
        {schema && !schema.behaviour?.hidden && 
            <Box sx={{width: '100%'}}>
              {settings.label && <Typography sx={{fontSize: 14}} color='text.secondary' gutterBottom component='div'>{settings.label}</Typography>}
              <pre><Typography sx={{fontSize: 12, mb:5}} color={"text.secondary"} gutterBottom component='div'>
                  {settings.help || ""}
                  {settings.helplink && 
                      <IconButton size='small' href={settings.helplink.linkTo} aria-label="help" target="_blank" edge='end' sx={{alignItems: "normal", '&.MuiIconButton-root:hover':{bgcolor: 'transparent'}}}>
                          <Tooltip title={settings.helplink.tooltip || "User guide"} placement="top" arrow><FontAwesomeIcon icon="fas fa-circle-question"/></Tooltip>
                      </IconButton>
                  }
                </Typography>
              </pre>
              <Box ref={d3Container} />
            </Box>}
      </>
  )
}

export default D3OrganizationChart;

D3OrganizationChart.propTypes = {
    schema: PropTypes.object.isRequired
};

const initialData = [
  {
    "id": "-1",
    "parentId": ""
  }
]