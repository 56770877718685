import React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { useThemeContext } from '@crema/utility/AppContextProvider/ThemeContextProvider';

const ButtonComponent = ({schema}) => {
    const {theme} = useThemeContext();
    return (
      <>
        {schema && !schema.behaviour?.hidden && <Box
          sx={{ position: 'fixed', right: 0, top: {xs: 85, xl: 125}, zIndex: 1110, }}
          onClick={() => { if (schema.onClick) schema.onClick(); }}
        >
          <Box
            sx={{
              borderRadius: '30px 0 0 30px',
              mb: 1,
              backgroundColor: schema.settings?.color || theme.palette.primary.main,
              '&:hover': { backgroundColor: schema.settings?.hover || theme.palette.secondary.main},
              '& button': { borderRadius: '30px 0 0 30px', 
                '&:focus': {
                    borderRadius: '30px 0 0 30px',
                },
              },
            }}
          >
            {schema.settings?.icon &&
                <IconButton>
                  <Tooltip title={schema.settings.label} placement="top">
                    <FontAwesomeIcon icon={schema.settings.icon.code} size={schema.settings.icon.size || "xs"} color={schema.settings.icon.color || theme.palette.primary.contrastText}/>
                  </Tooltip>
                </IconButton>
            }
          </Box>
        </Box>}
      </>
    );
}

export default ButtonComponent;

ButtonComponent.propTypes = {
  schema: PropTypes.object.isRequired,
};
