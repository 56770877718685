import React, { memo } from "react";
import { NodeResizer } from "reactflow";
import PropTypes from 'prop-types';

const CloudGroup =  ({ data, selected }) => {
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
            <NodeResizer color="#ff0071" isVisible={selected} minWidth={100} minHeight={30} />
            <div>{data.label}</div>
        </div>
    );
};

export default memo (CloudGroup);

CloudGroup.propTypes = {
    data: PropTypes.object.isRequired,
    selected: PropTypes.bool,
};

// backgroundImage: "url('/assets/flow/cloud.png')", 
//         backgroundPosition: 'center',
//         backgroundSize: 'contain',
//         backgroundRepeat: 'no-repeat'