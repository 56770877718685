import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

const ButtonBarComponent = ({schema}) => {
  useEffect (()=>{
    setTimeout (()=> {  schema.onInit && schema.onInit ();  }, 100);
  }, []);
  return (
    <>
      {schema && !schema.behaviour.hidden && !schema.behaviour.readonly && 
        <Stack direction={schema.settings.direction || "row"} spacing={schema.settings.spacing || 2} justifyContent={schema.settings.justifyContent || "center"} alignItems={schema.settings.alignItems || "center"} sx={{mb: 2, pt: 2}} >
          {schema.settings.actions.map ((action, index) => {
            if (!action.hidden)
              return (
                <Tooltip title={action.help || action.label} placement="top" key={index} arrow>
                  <Button  variant='contained' endIcon={<FontAwesomeIcon icon={action.icon.code} size={action.icon.size || "xs"}/>} sx={{backgroundColor: action.icon.color}}
                    onClick={() => (action.event && schema[action.event] && schema[action.event] ())}>
                    {action.label}
                  </Button>
                </Tooltip>
                )
          })}
      </Stack>}
    </>
  );
}

export default ButtonBarComponent;

ButtonBarComponent.propTypes = {
  schema: PropTypes.object.isRequired,
};
